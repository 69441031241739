<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title" @click="goBack">
          <i class="el-icon-arrow-left"></i><span>返回</span>
        </span>
      </div>
      <div class="orderContent">
        <div class="orderDetailTitle">订单详情</div>
        <el-form ref="form" :model="form" label-width="130px">
          <el-form-item label="订单状态：">
            <span>
              <i v-show="detail.orderStatus == '已完成'" class="el-icon-success success"
                ><span class="status_text">已完成</span></i
              >
              <i v-show="detail.orderStatus == '已取消'" class="el-icon-warning warning"
                ><span class="status_text">已取消</span></i
              >
              <i v-show="detail.orderStatus == '已退款'" class="el-icon-warning warning"
                ><span class="status_text">已退款</span></i
              >
              <i v-show="detail.orderStatus == '待支付'" class="el-icon-error error"
                ><span class="status_text">未支付</span></i
              >
            </span>
          </el-form-item>
          <el-form-item label="订单编号：">
            <div>{{ detail.orderId }}</div>
          </el-form-item>
          <el-form-item label="订单明细：">
            <div v-if="detail.payUser == '个人'">
              <el-table
                :data="detail.list"
                border
                :summary-method="getSummaries"
                show-summary
                style="width:408px"
              >
                <el-table-column prop="productName" label="类目"> </el-table-column>
                <el-table-column prop="" label="单价">
                  <template slot-scope="scope">
                    <span>￥{{ scope.row.productAmount }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="productCount" label="数量"> </el-table-column>
                <el-table-column prop="" label="总价">
                  <template slot-scope="scope">
                    <span>￥{{ scope.row.productSumAmount }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-if="detail.payUser == '企业'">
              <el-button type="primary" class="exporTable" @click="handleExportTable"
                >导出订单明细</el-button
              >
              <div class="formTable">
                <el-table
                  :data="detail.organizationOrderDetailRespDtoPageDto.list"
                  style="width:955px"
                >
                  <el-table-column prop="productName" label="类目"> </el-table-column>
                  <el-table-column prop="studentName" label="员工名称"> </el-table-column>
                  <el-table-column prop="productAmount" label="单价（元）">
                    <template slot-scope="scope">
                      <span>￥{{ scope.row.productAmount }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="buyMethodShow" label="购买方式"> </el-table-column>
                </el-table>
              </div>
              <div class="pageGin">
                <span class="item-total"
                  >共 {{ detail.organizationOrderDetailRespDtoPageDto.total }} 条记录 第{{
                    pager.current
                  }}/{{ detail.organizationOrderDetailRespDtoPageDto.pages }}页</span
                >
                <el-pagination
                  background
                  :current-page="pager.current"
                  :page-size="pager.size"
                  :page-sizes="[10, 20, 50, 100, 500]"
                  layout="prev, pager, next, sizes,jumper"
                  :total="pager.total"
                  class="item-pagin"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                >
                </el-pagination>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="支付渠道：">
            <div>{{ detail.payType }}</div>
          </el-form-item>
          <el-form-item label="订单创建时间：">
            <div>{{ detail.createTime }}</div>
          </el-form-item>
          <el-form-item label="订单支付时间：">
            <div>{{ detail.payTime }}</div>
          </el-form-item>
        </el-form>
      </div>
      <div class="pay">
        <div>购买方信息</div>
        <div class="payTip">购买类型：{{ detail.payUser }}</div>
        <div class="payTip">购买人：{{ detail.payUserName }}</div>
        <div class="payTip">联系方式：{{ detail.mobile }}</div>
      </div>

      <div class="payPrice">
        <el-form ref="formDetail" :model="formDetail" label-width="130px">
          <el-form-item label="订单金额：" class="payPriceNews">
            <div class="money">￥{{ detail.orderAmount }}</div>
          </el-form-item>
          <el-form-item label="优惠金额：" class="payPriceNews">
            <div class="money">￥{{ detail.discountAmount }}</div>
          </el-form-item>
          <el-form-item label="订单实付金额：" class="payPriceNews">
            <div class="actualMoney">￥{{ detail.amount }}</div>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import { myOrderDetail, myOrderDetailExport } from '@/api/teacher'
import to from 'await-to'
export default {
  data() {
    return {
      form: {},
      id: this.$route.query.id,
      detail: {},
      payTypeName: '',
      formDetail: {},
      pager: {
        current: 1,
        size: 10,
        total: 1,
      },
      list: [],
    }
  },
  created() {
    this.getOrderDetail()
  },
  methods: {
    async getOrderDetail() {
      const { current, size } = this.pager
      const [res, err] = await to(myOrderDetail({ current, size, id: this.$route.query.id }))
      if (err) return this.$message.warning(err.msg)
      this.detail = res.data.data
      if (res.data.data.organizationOrderDetailRespDtoPageDto.total == 0) {
        this.pager.total = 1
      } else {
        this.pager.total = res.data.data.organizationOrderDetailRespDtoPageDto.total
      }
    },
    async handleExportTable() {
      const [res, err] = await to(myOrderDetailExport({ id: this.$route.query.id }))
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '订单明细.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      //   console.log(columns, data)
      var price = 0
      data.map(v => {
        price = v.productSumAmount
      })
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小计'
          return
        }
        if (index === columns.length - 1) {
          sums[index] = '￥' + price
          return
        }
      })

      return sums
    },
    goBack() {
      this.$router.push('myOrderList')
    },
    refund() {
      this.$message({ type: 'warning', message: '暂未开通此功能' })
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getOrderDetail()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getOrderDetail()
    },
  },
}
</script>

<style scoped lang="scss">
.title {
  cursor: pointer;
}

.orderContent {
  padding-bottom: 20px;
  border-bottom: 1px solid #e2e2e2;
  ::v-deep .el-form-item {
    margin-bottom: 0;
  }
  .orderDetailTitle {
    background: #f6f6f6;
    line-height: 42px;
    font-size: 16px;
    color: #606266;
    padding-left: 20px;
  }
  .exporTable {
    width: 124px;
    height: 40px;
    background: #ff7b33;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #ff7b33;
    font-size: 14px;
  }
  .formTable {
    margin-top: 16px;
    width: 955px;
    opacity: 1;
    border: 1px solid #c2c2c2;
    ::v-deep .el-table {
      margin-bottom: 0;
      .is-leaf {
        border-bottom: 1px solid #e2e2e2 !important;
        padding: 1px 0;
        padding-left: 45px;
        color: #333333;
      }
      .el-table__row {
        background: #fff;
        &:last-child {
          td {
            padding: 9px 0;
            border-bottom: none;
            padding-left: 45px;
          }
        }
        td {
          padding: 9px 0;
          border-bottom: 1px solid #e2e2e2;
          padding-left: 45px;
        }
      }
      &::before {
        background-color: #fff;
      }
      .el-table__empty-block {
        min-height: 41px;
        .el-table__empty-text {
          line-height: 41px;
        }
      }
    }
  }
  .pageGin {
    display: flex;
    margin-top: 20px;
    margin-bottom: 40px;
    .item-total {
      padding-left: 22px;
      padding-right: 10px;
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    }
    .item-pagin {
      ::v-deep .btn-prev {
        min-width: 24px;
        height: 24px;
        background-color: #ffffff;
        border: 1px solid #c2c2c2;
      }
      ::v-deep .el-pager {
        height: 24px;
        li {
          min-width: 24px;
          height: 24px;
          font-size: 12px;
          line-height: 24px;
          background-color: #ffffff;
          border: 1px solid #c2c2c2;
          font-size: 12px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          &:not(.disabled).active {
            background-color: #ff7b33;
            border: none;
            color: #ffffff;
          }
        }
      }
      ::v-deep .btn-next {
        min-width: 24px;
        height: 24px;
        background-color: #ffffff;
        border: 1px solid #c2c2c2;
      }
      ::v-deep .el-pagination__jump {
        margin-left: 4px;
      }
    }
  }
}
.payPrice {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20%;
  :last-child {
    &.payPriceNews {
      margin-top: 10px;
    }
  }
  .payPriceNews {
    margin: 0;
    .money {
      font-size: 16px;
      color: #ff7b33;
    }
    .actualMoney {
      font-size: 20px;
      color: #ff7b33;
    }
  }
}
.backBtn {
  display: inline-block;
  padding: 5px 12px;
  color: #fff;
  font-size: 14px;
  background-color: #ff7b33;
  margin-left: 20px;
  cursor: pointer;
}
.refundBtn {
  float: right;
  padding: 3px 0;
}
.success {
  color: #52c41a;
  font-size: 14px;
}
.warning {
  color: #909399;
  font-size: 14px;
}
.error {
  color: #ff4646;
  font-size: 14px;
}
.status_text {
  padding: 0 5px;
}
.pay {
  padding: 23px 22px;
  color: #333333;
  font-size: 16px;
  border-bottom: 1px solid #e2e2e2;
}
.payTip {
  font-size: 14px;
  color: #606266;
  margin: 20px;
}
</style>
